/* General Reset and Styling */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Helvetica Neue', Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  transition: background-color 0.3s, color 0.3s;
}

body,
html {
  width: 100%;
  height: 100%;
  background-color: #1c1c1e;
  color: #fff;
}

/* App Container */
.app-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding: 4rem 2rem;
}

/* Shared Container Styles */
.costs-container,
.ticket-calculator-container,
.revenue-container,
.output-container {
  background: rgba(44, 44, 46, 0.85);
  padding: 2.5rem;
  margin: 2.5rem 0;
  border-radius: 1.5rem;
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.8);
  width: calc(100% - 4rem);
  max-width: 800px;
  backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.1); /* Subtle border */
}

.ticket-calculator-buttons {
  display: flex;
  justify-content: center; /* Centers buttons horizontally */
  gap: 1rem; /* Creates space between buttons */
  margin-top: 1rem; /* Additional space from the last input to buttons */
}

/* Form Elements */
label {
  display: block;
  margin-bottom: 1rem;
  font-size: 1.2rem;
  color: rgba(255, 255, 255, 0.9);
}

input[type='number'] {
  width: 100%;
  padding: 1.5rem;
  margin-bottom: 2rem;
  border: 2px solid transparent;
  background-color: #3a3a3c;
  border-radius: 1.5rem;
  color: #fff;
  font-size: 1.2rem;
  transition: border-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

input[type='number']:focus {
  border-color: #5cb85c;
  box-shadow: 0 0 0 2px #5cb85c inset;
}

/* Buttons */
button {
  margin-right: 0;
  padding: 1rem 2rem;
  border: none;
  background: linear-gradient(145deg, #5cb85c, #449d44);
  border-radius: 1.5rem;
  color: #fff;
  font-size: 1.2rem;
  cursor: pointer;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out,
    background-color 0.3s;
  box-shadow: 0 4px 14px rgba(92, 184, 92, 0.4);
}

button:hover {
  background: linear-gradient(145deg, #4cae4c, #398439);
  box-shadow: 0 6px 20px rgba(92, 184, 92, 0.5);
  transform: translateY(-5px);
}

button:active {
  background: linear-gradient(145deg, #398439, #4cae4c);
  box-shadow: 0 3px 10px rgba(92, 184, 92, 0.5);
  transform: translateY(-2px);
}

/* Output Styling */
.output-container h3 {
  font-size: 1.5rem;
  margin-bottom: 1.5rem;
  text-shadow: 0 2px 6px rgba(0, 0, 0, 0.5);
  transition: text-shadow 0.3s ease-in-out;
}

/* Hover and Active States for Output */
.output-container h3:hover {
  text-shadow: 0 2px 10px rgba(255, 255, 255, 0.6);
}

/* Subtle Animation for Load */
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.costs-container,
.ticket-calculator-container,
.revenue-container,
.output-container {
  animation: fadeInUp 0.5s ease-out forwards;
}

/* Responsive Design */
@media (max-width: 768px) {
  .app-container {
    padding: 2rem 1rem;
  }

  input[type='number'],
  button {
    padding: 1rem;
  }

  label {
    font-size: 1rem;
  }

  .output-container h3 {
    font-size: 1.3rem;
  }
}
